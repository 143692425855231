import React, { FC, ReactNode } from 'react';
import AiContentRemainingAttemptsBadge from 'app/modules/aiContent/components/AiContentRemainingAttemptsBadge';
import { useTextEditorAiContent } from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import { Button, Icon, mergeTailwindClasses } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import {
  BrandHubNewCreativeFormProvider,
  BrandHubNewCreativeFormValues,
} from 'app/modules/brandHub/components/brandHubCreatives/brandHubNewCreativeForm/BrandHubNewCreativeFormProvider';
import { useMutation } from 'react-query';
import ArrayUtils from 'app/utils/array';
import { BrandHubApi } from 'app/modules/brandHub/api/brandHub';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import clsx from 'clsx';
import BrandHubNewCreativeForm from 'app/modules/brandHub/components/brandHubCreatives/BrandHubNewCreativeForm';
import TextGeneratedResults from 'app/modules/aiContent/components/results/TextGeneratedResults';
import TextEditorAiContentPopupGenerateFooter from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentPopupGenerateTextFooter';

type Props = {
  renderResultAction?: (text: string) => ReactNode;
  pageType?: number;
};

const TextEditorAIContentPopup: FC<Props> = ({
  renderResultAction,
  pageType,
}) => {
  const { queries } = useTextEditorAiContent();
  const { accountPlanType } = useSubscriptionInfo();
  const { t } = useTranslation();

  const createCreatives = useMutation(
    (values: BrandHubNewCreativeFormValues) => {
      return Promise.all(
        ArrayUtils.generate(values.creativeCount).map((_) =>
          BrandHubApi.createCreative({
            ...values,
            pageType: values.pageType ?? pageType,
            planType: accountPlanType,
          }),
        ),
      );
    },
  );

  const onSubmit = (values: BrandHubNewCreativeFormValues) => {
    if (createCreatives.isLoading) return;

    createCreatives.mutate(values);
  };

  return (
    <BrandHubNewCreativeFormProvider onSubmit={onSubmit}>
      {(context) => (
        <div
          className="tw-shadow tw-w-[480px] tw-rounded tw-border tw-border-grayscale-20 tw-bg-white"
          data-name="ai-content-popup"
        >
          <div className="tw-p-4 tw-pb-0">
            {!createCreatives.isLoading && createCreatives.data && (
              <Button
                variant="plain"
                className="tw-mb-4 tw-capitalize"
                data-name="ai-content-back"
                iconBefore={
                  <Icon
                    icon={faChevronLeft}
                    className="tw-text-grayscale-100"
                  />
                }
                onClick={() => createCreatives.reset()}
              >
                {t('back')}
              </Button>
            )}
            <div className="tw-flex tw-justify-between">
              <div className="tw-flex tw-items-center tw-gap-2 tw-text-lg tw-font-semibold tw-text-grayscale-180">
                <span>{t('aiContent')}</span>
              </div>
              <div>
                <AiContentRemainingAttemptsBadge options={queries.options} />
              </div>
            </div>
          </div>
          <div
            className={mergeTailwindClasses(
              clsx(
                'tw-flex tw-h-[450px] tw-w-full tw-flex-col tw-overflow-hidden [@media(max-height:1000px)]:tw-h-[390px]',
                {
                  'tw-h-[414px] [@media(max-height:1000px)]:tw-h-[354px]':
                    !createCreatives.isLoading && createCreatives.data,
                },
              ),
            )}
            data-name="ai-content-generate-text"
          >
            {!createCreatives.data && !createCreatives.isLoading && (
              <BrandHubNewCreativeForm className="tw-mt-4 tw-px-4 tw-pb-4" />
            )}

            {(createCreatives.data || createCreatives.isLoading) && (
              <TextGeneratedResults
                suggestions={
                  createCreatives.data?.map((creative) => creative) ?? []
                }
                isLoading={createCreatives.isLoading}
                renderAction={renderResultAction}
                classNames={{
                  root: 'tw-overflow-y-auto tw-flex-grow',
                  loadingImg: 'tw-h-[8.5rem]',
                  emptyImg: 'tw-h-[8.5rem]',
                }}
              />
            )}
            {!createCreatives.isLoading && (
              <TextEditorAiContentPopupGenerateFooter
                isLoading={createCreatives.isLoading}
                hasResults={!!createCreatives.data}
                onSubmit={context.submit}
              />
            )}
          </div>
        </div>
      )}
    </BrandHubNewCreativeFormProvider>
  );
};

export default TextEditorAIContentPopup;
