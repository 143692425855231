import { FC } from 'react';
import ProfileSelectorOption from 'app/components/profileSelector/components/ProfileSelectorOption';
import { useUser } from 'modules/user/userSelector';
import { hasPermission } from '@kontentino/react-permissions-gate';
import { UserPermission } from 'constants/userPermission';
import { useNavigate } from 'react-router-dom';
import { createSearchFromParams, openBlank } from 'utils/url';
import { modalRoutes } from 'constants/routes';
import useIsHoldingCtrlOrCmdKey from 'app/hooks/useIsHoldingCtrlOrCmdKey';
import { Logo } from 'types/Page';
import ProfileSelectorInfoMessage from 'app/components/profileSelector/components/ProfileSelectorInfoMessage';
import { useSocialLoginContext } from 'app/context/socialLogin';

type Option = {
  id: number;
  name: string;
  logoSrc?: string;
  socialMediaType?: number;
  isGroup?: boolean;
  isEditable?: boolean;
  isDemo?: boolean;
  pages?: {
    id: number;
    name: string;
    type: number;
    logo: Logo;
    isConnected: boolean | null;
    isGlobal?: boolean;
  }[];
  isConnected?: boolean;
  isGlobal?: boolean;
};

type Props = {
  selectedProfilesIds: number[];
  onSelectProfile: (id: number, multiple?: boolean) => void;
  selectedProfileGroupsIds: number[];
  onSelectProfileGroup: (id: number, multiple?: boolean) => void;
  data: Option[];
};

const getDataCy = (option: Option) => {
  return option.isGroup
    ? 'page-selector-profile-group-item'
    : `page-selector-page-item`;
};

const PostsProfileSelectorDataList: FC<Props> = ({
  data,
  onSelectProfile,
  onSelectProfileGroup,
  selectedProfilesIds,
  selectedProfileGroupsIds,
}) => {
  const { permissionsKeys } = useUser();
  const navigate = useNavigate();
  const socialLogin = useSocialLoginContext();
  const { isCtrlOrCmdActive } = useIsHoldingCtrlOrCmdKey();

  if (data.length === 0) {
    return <ProfileSelectorInfoMessage message="Nothing found" />;
  }

  const isMultiSelect =
    [...selectedProfilesIds, ...selectedProfileGroupsIds].length > 1 ||
    isCtrlOrCmdActive;

  const handleOptionClick = (option: Option) => {
    if (option.isGroup) {
      onSelectProfileGroup(option.id, isMultiSelect);
    } else {
      onSelectProfile(option.id, isMultiSelect);
    }
  };

  const handleEditProfileGroupClick = (id: number) => {
    navigate({
      hash: modalRoutes.MANAGE_PROFILE_GROUP,
      search: `?id=${id}`,
    });
  };

  const handleEditProfileClick = (id: number, isGlobal?: boolean) => {
    isGlobal
      ? openBlank(`/Projects/Pages/page:${id}/type:global`)
      : openBlank(`/Projects/Pages/page:${id}/type:my`);
  };

  const canEditProfile = (isDemo: boolean = false) => {
    return hasPermission(permissionsKeys, UserPermission.PAGE_EDIT) && !isDemo;
  };

  const canEditProfileGroup = (isEditable: boolean = false) => {
    return (
      isEditable &&
      hasPermission(permissionsKeys, UserPermission.PAGE_PRESETS_MANAGE)
    );
  };

  const handleConnectionClick = (page: { id: number; type: number | null }) => {
    if (page.type) {
      socialLogin.authorize({
        pageId: page.id,
        pageType: page.type,
      });
      navigate({
        hash: modalRoutes.ASSOCIATE_POSTS_PROFILE,
        search: createSearchFromParams(page),
      });
    }
  };

  const canReconnectOption = (option: Option) => {
    return option.isGroup
      ? canEditProfileGroup(option.isEditable)
      : canEditProfile(option.isDemo);
  };

  return (
    <>
      {data.map((option) => (
        <ProfileSelectorOption
          key={`${option.isGroup ? 'group-' : ''}option-${option.id}`}
          isSingleSelect={!isMultiSelect}
          subOptions={
            option.pages?.map((page) => ({
              id: page.id,
              label: page.name,
              logoSrc: page.logo?.src ?? undefined,
              socialMediaType: page.type,
              isConnected: !!page.isConnected,
              isGlobal: page.isGlobal,
            })) ?? []
          }
          dataCy={getDataCy(option)}
          option={{ ...option, label: option.name }}
          isDemo={option.isDemo}
          isGlobal={option.isGlobal}
          eventHandlers={{
            onClick: () => handleOptionClick(option),
            onEditClick: option.isGroup
              ? canEditProfileGroup(option.isEditable)
                ? () => handleEditProfileGroupClick(option.id)
                : undefined
              : canEditProfile(option.isDemo)
              ? () => handleEditProfileClick(option.id, option.isGlobal)
              : undefined,
            onReconnectClick:
              option.isConnected || !canReconnectOption(option)
                ? undefined
                : () =>
                    handleConnectionClick({
                      id: option.id,
                      type: option.socialMediaType ?? null,
                    }),
            onSubOptionReconnectClick: canReconnectOption(option)
              ? (page) => handleConnectionClick(page)
              : undefined,
          }}
          isChecked={
            option.isGroup
              ? selectedProfileGroupsIds.includes(option.id)
              : selectedProfilesIds.includes(option.id)
          }
        />
      ))}
    </>
  );
};

export default PostsProfileSelectorDataList;
