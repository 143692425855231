import { faArrowTrendUp } from '@fortawesome/pro-regular-svg-icons/faArrowTrendUp';
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons/faBadgeCheck';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons/faCalendarDay';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faIcons } from '@fortawesome/pro-regular-svg-icons/faIcons';
import { faLanguage } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import { faListCheck } from '@fortawesome/pro-regular-svg-icons/faListCheck';
import { faNoteSticky } from '@fortawesome/pro-regular-svg-icons/faNoteSticky';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { faSquareCheck } from '@fortawesome/pro-regular-svg-icons/faSquareCheck';
import { faTag } from '@fortawesome/pro-regular-svg-icons/faTag';
import React, { ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faMapPin } from '@fortawesome/pro-light-svg-icons/faMapPin';

export type WidgetVariant = keyof typeof WIDGET_VARIANT_PROPERTIES;

export const WIDGET_VARIANT_PROPERTIES = {
  translation: {
    className: 'tw-text-blue-alt-dark tw-bg-blue-alt-light',
    icon: faLanguage,
    emptyMessage: 'The post does not contain any translation yet',
    title: 'Translation',
    titleTooltip: undefined,
  },
  postCompletion: {
    className: 'tw-text-burgundy-dark tw-bg-burgundy-light',
    icon: faBadgeCheck,
    emptyMessage: `You don't have any completion yet.`,
    title: 'Post completion',
    titleTooltip:
      'Encountered scheduling errors will be displayed here. Follow the provided instructions to resolve the issue.',
  },
  labels: {
    className: 'tw-text-purple-100 tw-bg-purple-10',
    icon: faTag,
    emptyMessage: `You don't have any labels.`,
    title: 'Labels',
    titleTooltip:
      'Create and assign labels to your posts for a better overview.',
  },
  notes: {
    className: 'tw-text-orange-100 tw-bg-orange-10',
    icon: faNoteSticky,
    emptyMessage: `You don't have any notes yet.`,
    title: 'Notes',
    titleTooltip: `Leave a note for yourself and your team when creating a post. This feature is for internal purposes only and won't be published.`,
  },
  boostBudget: {
    className: 'tw-text-danger-100 tw-bg-danger-10',
    icon: faArrowTrendUp,
    emptyMessage: `You don’t have a boost budget.`,
    title: 'Boost budget',
    titleTooltip: (
      <span>
        The <strong>boost budget</strong> field in Kontentino <br /> is only for
        informative purposes.
      </span>
    ),
  },
  postRequirements: {
    className: 'tw-text-turquoise-100 tw-bg-turquoise-10',
    icon: faListCheck,
    emptyMessage: `You don't have any post requirements yet.`,
    title: 'Post checklist',
    titleTooltip:
      'Write down the tasks you want to complete and check them off when done.',
  },
  tasks: {
    className: 'tw-text-success-120 tw-bg-success-10',
    icon: faSquareCheck,
    emptyMessage: `You don't have any tasks yet.`,
    title: 'Task',
    titleTooltip: undefined,
  },
  communication: {
    className: 'tw-text-blueberry-dark tw-bg-blueberry-light',
    icon: faComment,
    emptyMessage: `You don't have any comments yet.`,
    title: 'Communication',
    titleTooltip: undefined,
  },
  postContent: {
    className: 'tw-text-primary-100 tw-bg-primary-10',
    icon: faIcons,
    emptyMessage: `You don't have any content yet.`,
    title: 'Post content',
    titleTooltip: undefined,
  },
  localPosts: {
    className: 'tw-text-primary-100 tw-bg-primary-10',
    icon: faMapPin,
    emptyMessage: `You don't have any content yet.`,
    title: 'Local posts',
    titleTooltip:
      'You can leave instructions for your other Localized content users. These instructions will not be published and have only informative purpose within Kontentino.',
  },
  localPost: {
    className: 'tw-text-primary-100 tw-bg-primary-10',
    icon: faMapPin,
    emptyMessage: `You don't have any content yet.`,
    title: 'Local post',
    titleTooltip: '',
  },
  publishingMethod: {
    className: 'tw-text-grayscale-100 tw-bg-grayscale-10',
    icon: faPaperPlane,
    emptyMessage: `You don't have any publishing method yet.`,
    title: 'Publishing method',
    titleTooltip: undefined,
  },
  postPlanning: {
    className: 'tw-text-teal-120 tw-bg-teal-10',
    icon: faCalendarDay,
    emptyMessage: `You don't have any planning yet.`,
    title: 'Post planning',
    titleTooltip: undefined,
  },
} satisfies Record<
  string,
  {
    className: string;
    icon: IconDefinition;
    emptyMessage: string;
    title: string;
    titleTooltip: ReactNode | undefined;
  }
>;
