import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useToast } from 'app/hooks/useToast';
import { prependHttp } from 'utils/url';
import { Button, Field, Icon, IconButton, TextInput } from '@kontentino/ui';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { BrandHubApi } from 'app/modules/brandHub/api/brandHub';
import { WebCrawlResult } from 'app/modules/brandHub/types';
import { ApiClientError } from 'api/client';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import KeyCodes from 'constants/keyCodes';

type Props = {
  onChange(webCrawl: WebCrawlResult | undefined): void;
  value?: WebCrawlResult;
  dataNamePrefix: string;
  dataCy?: string;
  placeholder?: string;
};

type FormState = z.infer<typeof schema>;

const schema = z.object({
  url: z.string().url(),
});

const WebCrawlLinkField: FC<Props> = ({
  value,
  onChange,
  placeholder,
  dataNamePrefix,
}) => {
  const toast = useToast();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<FormState>({
    defaultValues: { url: value?.url ?? '' },
    resolver: zodResolver(schema),
  });
  const { t } = useTranslation();
  const fetchWebCrawl = useMutation(BrandHubApi.fetchWebCrawl, {
    onSuccess(webCrawl) {
      onChange(webCrawl);
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('unableToRetrieveLinkInfo'), 'error');
    },
  });

  const registerUrl = register('url');
  const watchedUrl = watch('url');

  const onSubmit = handleSubmit(({ url }) =>
    fetchWebCrawl.mutate({ url: prependHttp(url) }),
  );

  function resetCrawl() {
    onChange(undefined);
    reset({ url: '' });
    fetchWebCrawl.reset();
  }

  return (
    <Field.Group>
      {errors.url?.message && <Field.Error>{errors.url.message}</Field.Error>}
      <div
        className="tw-flex tw-gap-2"
        data-name={`${dataNamePrefix}-fetch-web-link`}
      >
        <div className="tw-flex-1">
          <TextInput
            disabled={!!value}
            {...registerUrl}
            onPaste={(e) => {
              e.preventDefault();
              setValue('url', prependHttp(e.clipboardData.getData('text')));
            }}
            onBlur={(e) => {
              registerUrl.onBlur(e);
              if (e.target.value) {
                setValue('url', prependHttp(e.target.value));
              }
            }}
            onKeyDown={(e) => {
              if (e.key === KeyCodes.Enter) {
                e.preventDefault();
                setValue('url', prependHttp(e.currentTarget.value));
                onSubmit();
              }
            }}
            placeholder={placeholder ?? t('enterLinkToFetch')}
            iconAfter={
              value && (
                <IconButton variant="plain" onClick={resetCrawl}>
                  <Icon icon={faTrash} />
                </IconButton>
              )
            }
          />
        </div>
        {!value && (
          <Button
            type="button"
            className="tw-shrink-0"
            isLoading={fetchWebCrawl.isLoading}
            disabled={!watchedUrl}
            data-name={`${dataNamePrefix}-fetch-web-link_set-link`}
            onClick={onSubmit}
          >
            {t('fetchLink')}
          </Button>
        )}
      </div>
      {value && (
        <div className="tw-flex tw-items-center tw-gap-4 tw-rounded tw-border tw-border-grayscale-20 tw-bg-grayscale-5 tw-p-2">
          {value.image ? (
            <img
              alt="url-preview"
              className="tw-h-20 tw-w-20 tw-shrink-0 tw-rounded tw-object-cover"
              src={value.image}
            />
          ) : (
            <div className="tw-flex tw-h-20 tw-w-20 tw-shrink-0 tw-items-center tw-justify-center tw-rounded tw-bg-grayscale-20 tw-object-cover">
              <Icon icon={faImage} />
            </div>
          )}
          <div className="tw-min-w-0">
            <div className="tw-line-clamp-2 tw-text-base">{value.title}</div>
            <p className="tw-text-grasycale-120 tw-line-clamp-2 tw-text-sm">
              {value.description}
            </p>
          </div>
        </div>
      )}
    </Field.Group>
  );
};

export default WebCrawlLinkField;
