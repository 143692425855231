import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { BrowserStorage } from 'utils/browserStorage';
import { useMemo } from 'react';

type Option = {
  label: string;
  value: string;
};

type Key = 'language' | 'brandProfileId';

type LastSelectedOptionsStore = {
  [key in Key]: Option[];
} & {
  addOption: (key: Key, option: Option) => void;
  getGroupedOptions: (
    key: Key,
    allOptions: Option[],
  ) => { label: string; options: Option[] }[];
};

const useLastSelectOptionsStore = create<LastSelectedOptionsStore>()(
  persist(
    (set, get) => ({
      language: [],
      brandProfileId: [],
      addOption: (key: Key, option: Option) => {
        if (!key || !option) return;
        set((state) => ({
          [key]: [
            option,
            ...((state[key] || []) as Option[]).filter(
              (opt) => opt.value !== option.value,
            ),
          ].slice(0, 3),
        }));
      },
      getGroupedOptions: (
        key: Key,
        allOptions: Option[],
      ): { label: string; options: Option[] }[] => {
        if (!key || !Array.isArray(allOptions)) return [];
        const lastUsedOptions = get()[key] || [];

        return [
          {
            label: 'Last used',
            options: lastUsedOptions,
          },
          {
            label: 'All',
            options: allOptions.filter(
              (option) =>
                !lastUsedOptions.map((opt) => opt.value).includes(option.value),
            ),
          },
        ];
      },
    }),
    {
      name: BrowserStorage.keys.Stores.OptionsSelect,
    },
  ),
);

export function useLastSelectOptions() {
  const store = useLastSelectOptionsStore();

  return useMemo(() => store, [store]);
}
