import React, { FC, FocusEventHandler } from 'react';
import { EditorContent } from '@tiptap/react';
import OptionsBar, {
  OptionsBarConfig,
} from 'app/modules/textEditor/components/OptionsBar';
import {
  useTextEditor,
  UseTextEditorProps,
} from 'app/modules/textEditor/hooks/useTextEditor';
import {
  DEFAULT_TEXT_EDITOR_FEATURES,
  TextEditorFeature,
} from 'app/modules/textEditor/constants/textEditorFeature';
import { stopPropagation } from 'utils/mouseEvent';
import clsx from 'clsx';
import { Button, mergeTailwindClasses, Tooltip } from '@kontentino/ui';
import EditorRoot from 'app/modules/textEditor/components/EditorRoot';
import FileAttachments from 'app/modules/textEditor/components/FileAttachments';
import { useTranslation } from 'react-i18next';
import TextEditorAiContentRefineTextSelection from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentRefineTextSelection';
import TextEditorAiContentProvider from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import { util } from 'zod';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';
import MakePartial = util.MakePartial;

type Props = Omit<
  UseTextEditorProps,
  'features' | 'placeholder' | 'valueType' | 'disabled' | 'editorClassName'
> & {
  optionsBarConfig?: OptionsBarConfig;
  dataPrefix?: string;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  features: UseTextEditorProps['features'];
  placeholder?: UseTextEditorProps['placeholder'];
  valueType?: UseTextEditorProps['valueType'];
  disabled?: string | boolean;
  classNames?: {
    root?: string;
    rootFocused?: string;
    editorWrapper?: string;
    editor?: string;
    editorContent?: string;
    optionsBar?: string;
    dropOverlay?: string;
    fileAttachments?: string;
    editSaveButton?: string;
    editCancelButton?: string;
  };
  accountPlanType: string;
  isEditing?: boolean;
  onSaveEdit?: () => void;
  onCancelEdit?: () => void;
};

const TextEditorComponent: FC<Props> = ({
  features,
  placeholder = 'Write caption, #hashtags, @mentions, link or add emoji 🙂 ...',
  valueType = 'raw',
  dataPrefix,
  disabled,
  classNames,
  isEditing,
  onSaveEdit,
  onCancelEdit,
  ...props
}) => {
  const { t } = useTranslation();
  const { editor, fileAttachmentStorage } = useTextEditor({
    ...props,
    features,
    placeholder,
    valueType,
    disabled: !!disabled,
    editorClassName: mergeTailwindClasses(
      clsx(' tw-px-4 tw-py-3', classNames?.editor),
    ),
  });
  const hasValidValue = props.initialValue?.trim();

  return (
    <EditorRoot
      classNames={classNames}
      isFocused={editor?.isFocused}
      disabled={disabled}
      {...fileAttachmentStorage.dropzone.getRootProps()}
      filesDropzoneShown={fileAttachmentStorage.dropzone.isDragActive}
      data-name={`${dataPrefix}-text-editor`}
      data-cy={`${dataPrefix}-text-editor`}
    >
      <div
        className={mergeTailwindClasses(
          clsx('tw-cursor-text', classNames?.editorWrapper),
        )}
      >
        <EditorContent
          editor={editor}
          disabled={!!disabled}
          onKeyDown={stopPropagation()}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          className={mergeTailwindClasses(
            clsx(
              'tw-max-h-[300px] tw-overflow-y-auto',
              classNames?.editorContent,
            ),
          )}
        />
        {isEditing && (
          <div className="tw-flex tw-items-center">
            <Button
              variant={'ghost'}
              className={classNames?.editCancelButton}
              onClick={onCancelEdit}
            >
              {t('cancel')}
            </Button>
            <Tooltip
              content={
                !hasValidValue ? t('validation.addCommentText') : undefined
              }
            >
              <div>
                <Button
                  disabled={!hasValidValue}
                  variant={'ghost'}
                  className={classNames?.editSaveButton}
                  onClick={onSaveEdit}
                >
                  {t('save')}
                </Button>
              </div>
            </Tooltip>
          </div>
        )}
        {!isEditing && editor && (
          <OptionsBar
            dataNamePrefix={`${dataPrefix}-text-editor`}
            editor={editor}
            optionsBarConfig={props.optionsBarConfig}
            pageType={props.page?.type as PageNumberType | undefined}
            isDisabled={!!disabled}
            features={features}
            dropzone={fileAttachmentStorage.dropzone}
            className={mergeTailwindClasses(
              clsx('tw-px-4 tw-pb-3 tw-pt-1', classNames?.optionsBar),
            )}
            pageId={props.page?.id}
          />
        )}
      </div>
      {features.includes(TextEditorFeature.FileAttachments) && (
        <FileAttachments
          attachments={fileAttachmentStorage.fileAttachments}
          onRemoveClick={fileAttachmentStorage.removeFileById}
          isDisabled={!!disabled}
          className={classNames?.fileAttachments}
        />
      )}
      {features.includes(TextEditorFeature.AIContent) && editor && (
        <TextEditorAiContentRefineTextSelection editor={editor} />
      )}
    </EditorRoot>
  );
};

function createTextEditor(): FC<MakePartial<Props, 'features'>> {
  return ({ features = DEFAULT_TEXT_EDITOR_FEATURES, ...props }) => (
    <TextEditorAiContentProvider
      accountPlanType={props.accountPlanType}
      disabled={!features.includes(TextEditorFeature.AIContent)}
    >
      <TextEditorComponent {...props} features={features} />
    </TextEditorAiContentProvider>
  );
}

const TextEditor = createTextEditor();

export default TextEditor;
