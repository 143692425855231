import { Button, Icon, IconButton, Tooltip } from '@kontentino/ui';
import React, { FC } from 'react';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons/faArrowsRotate';
import useSyncProfileInsights from 'app/modules/insights/hooks/useSyncProfileInsights';
import { Page } from 'app/modules/insights/types/Page';
import { useApolloClient } from '@apollo/client';
import Popup from 'utils/popup';
import ProgressIndicator from 'app/components/ProgressIndicator';
import { pluralize } from 'utils/lang';
import { useIsSyncDisabled } from 'app/modules/insights/components/syncProfileInsightsButton/hooks/useIsSyncDisabled';

type Props = {
  page: Page;
};

const SyncProfileInsightsButton: FC<Props> = (props) => {
  const apolloClient = useApolloClient();
  const {
    syncManualTrigger,
    syncAutoTrigger,
    jobStatusProgress,
    lastFinishedAt,
    cleanup,
  } = useSyncProfileInsights({
    id: props.page.id,
    pageType: props.page._type,
  });
  const { isSyncDisabled, remainingMinutes } =
    useIsSyncDisabled(lastFinishedAt);

  function onRefresh() {
    cleanup();
    apolloClient.resetStore();
  }

  async function onSyncData() {
    const { isConfirmed } = await Popup.confirm({
      title: 'Are you sure you want to sync your data? ',
      text: 'Syncing your data will fetch the most up-to-date data for a selected social media profile. This action should take anywhere between 1-15 minutes.',
      confirmButtonText: 'Yes, I’m sure',
      cancelButtonText: 'Cancel',
    });

    if (isConfirmed) {
      syncManualTrigger.mutate();
    }
  }

  function getTooltipContent() {
    if (syncManualTrigger.isLoading && jobStatusProgress !== null) {
      return `Completion: ${jobStatusProgress.batch.progress}%`;
    }

    if (isSyncDisabled) {
      return `You are allowed to sync data again in ${pluralize(
        remainingMinutes,
        'minute',
      )}`;
    }
  }

  function getCanSeeRefreshButton() {
    return syncManualTrigger.data?.completed && syncAutoTrigger.data !== null;
  }

  return getCanSeeRefreshButton() ? (
    <Button
      variant="success"
      onClick={onRefresh}
      className="tw-h-[36px]"
      iconBefore={<Icon icon={faArrowsRotate} />}
    >
      Refresh
    </Button>
  ) : (
    <Tooltip content={getTooltipContent()}>
      <div>
        <IconButton
          className="tw-h-[36px]"
          variant="secondary"
          disabled={isSyncDisabled}
          onClick={syncManualTrigger.isLoading ? undefined : onSyncData}
          icon={
            syncManualTrigger.isLoading ? (
              <ProgressIndicator
                progress={jobStatusProgress?.batch.progress ?? 0}
              />
            ) : (
              <Icon icon={faArrowsRotate} />
            )
          }
        />
      </div>
    </Tooltip>
  );
};

export default SyncProfileInsightsButton;
