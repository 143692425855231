import React, { FC, useEffect } from 'react';
import {
  Button,
  Divider,
  Field,
  Select,
  TextArea,
  TextInput,
  Tooltip,
} from '@kontentino/ui';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useBoolean from 'utils/hooks/useBoolean';
import BrandHubSuggestProfileModal from '../BrandHubSuggestProfileModal';
import { useTranslation } from 'react-i18next';
import { AIContentOptionValue } from 'app/modules/posts/api/aiContent';
import useAiContentQueries from 'app/modules/aiContent/hooks/useAiContentQueries';
import { BrandProfile } from 'app/modules/brandHub/types';
import ValidationScheme from 'app/constants/validationSchemes';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import { formatDateTime } from 'app/utils/date';
import Popup from 'utils/popup';
import FeatureBadge from 'app/components/FeatureBadge';
import { useToast } from 'app/hooks/useToast';
import { useMutation, useQueryClient } from 'react-query';
import { BrandHubApi } from 'app/modules/brandHub/api/brandHub';
import { queryKey } from 'constants/queryKey';
import { ApiClientError } from 'api/client';
import Modal from 'components/shared/Modal';
import SingleImageFilePicker from 'app/components/SingleImageFilePicker';

const formSchema = z.object({
  id: z.string().optional(),
  name: ValidationScheme.RequiredString(),
  usp: ValidationScheme.RequiredString(),
  audience: ValidationScheme.RequiredString(),
  language: ValidationScheme.RequiredString(),
  story: ValidationScheme.RequiredString(),
  otherInfo: z.string(),
  textSample: z.string(),
  logoSrc: z.instanceof(File).or(z.string()).nullable(),
});

const defaultValues = {
  id: '',
  name: '',
  usp: '',
  audience: '',
  language: '',
  story: '',
  otherInfo: '',
  textSample: '',
  logoSrc: null,
};

type FormValues = z.infer<typeof formSchema>;

interface Props {
  profileToEdit?: BrandProfile;
  onClose: () => void;
  initialValues?: Partial<FormValues>;
  eventHandlers: {
    onProfileSave: (profile: BrandProfile) => void;
    onRemainingProfilesChange: (profiles: BrandProfile[]) => void;
  };
}

function mapOptionToSelect(option: AIContentOptionValue) {
  return {
    value: option.key,
    label: option.label,
  };
}

const BrandHubManageProfileModalForm: FC<Props> = ({
  profileToEdit,
  onClose,
  eventHandlers,
  initialValues,
}) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...defaultValues,
      ...initialValues,
    },
  });
  const profileId = profileToEdit?.id;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [suggestProfileModalShown, setSuggestProfileModalShown] = useBoolean(
    !profileId,
  );
  const { accountPlanType } = useSubscriptionInfo();
  const { options } = useAiContentQueries({ accountPlanType });
  const toast = useToast();

  const saveProfile = useMutation(BrandHubApi.saveBrandProfile, {
    onSettled() {
      queryClient.invalidateQueries(queryKey.brandHubProfiles());
      onClose();
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
    },
    onSuccess(data) {
      eventHandlers.onProfileSave(data);
    },
  });

  const deleteProfile = useMutation(BrandHubApi.deleteBrandProfile, {
    onSettled() {
      queryClient.invalidateQueries(queryKey.brandHubProfiles());
      onClose();
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
    },
  });

  useEffect(() => {
    if (profileToEdit && !form.getValues().id) {
      form.reset({
        ...profileToEdit,
        id: profileToEdit.id.toString(),
      });
    }
  }, [profileToEdit, form]);

  function onSubmit(data: FormValues) {
    saveProfile.mutate(data);
  }

  async function onDeleteProfile(id: string) {
    const { isConfirmed } = await Popup.confirm();

    if (!isConfirmed) return;

    deleteProfile.mutate(id);

    const remainingProfiles = queryClient
      .getQueryData<BrandProfile[]>(queryKey.brandHubProfiles())
      ?.filter((profile) => profile.id !== id);

    if (remainingProfiles) {
      eventHandlers.onRemainingProfilesChange(remainingProfiles);
    }
  }

  function onFormValuesSuggested({
    id,
    name,
    language,
    ...rest
  }: BrandProfile) {
    toast(t('brandProfileSuggestedFromPosts'), 'success');
    const currentValues = form.getValues();

    form.reset({
      id: form.getValues().id,
      name: currentValues.name || name,
      language: currentValues.language || language,
      ...rest,
    });
  }

  async function showSuggestProfileModal() {
    // const { id, name, language, logoSrc, ...overridableFields } =
    //   form.getValues();

    // const isFilled = Object.values(overridableFields).some((value) => !!value);

    // if (isFilled) {
    //   const { isConfirmed } = await Popup.confirm({
    //     title: t('generateBrandProfileTitle'),
    //     width: '420px',
    //     text: t('generateBrandProfileConfirmDescription'),
    //     confirmButtonText: t('ok'),
    //   });
    //
    //   if (!isConfirmed) return;
    // }

    setSuggestProfileModalShown.on();
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Modal.Header
        title={
          <>
            {t(profileId ? 'editBrandProfile' : 'createBrandProfile')}
            {!profileId && (
              <FeatureBadge className="tw-ml-2 tw-uppercase">
                {t('beta')}
              </FeatureBadge>
            )}
          </>
        }
        subtitle={t('fillDetailsAboutBrandProfile')}
      />
      <Modal.Content className="tw-space-y-4 tw-pb-4">
        {profileToEdit && (
          <Field.Group>
            <Field.Label required>{t('lastChange')}</Field.Label>
            <TextInput
              value={formatDateTime(profileToEdit.updatedAt)}
              disabled
            />
          </Field.Group>
        )}

        <div>
          <Button
            onClick={showSuggestProfileModal}
            size="small"
            variant="tertiary"
            data-name="brand-hub_manage-brand-profile_suggest-profile-button"
          >
            {t('generateFromMyPreviousPosts')}
          </Button>
          <BrandHubSuggestProfileModal
            isOpen={suggestProfileModalShown}
            onClose={setSuggestProfileModalShown.off}
            onBrandProfileSuggested={onFormValuesSuggested}
          />
        </div>

        {/* Form fields */}
        <Field.Group>
          <Field.Label required>{t('brandName')}</Field.Label>
          <TextInput
            data-name="brand-hub_manage-brand-profile_name"
            placeholder={t('brandNamePlaceholder')}
            {...form.register('name')}
          />
          {!!form.formState.errors.name?.message && (
            <Field.Error>{form.formState.errors.name?.message}</Field.Error>
          )}
        </Field.Group>

        <Field.Group>
          <Field.Label required>{t('outputLanguage')}</Field.Label>
          <Controller
            name="language"
            control={form.control}
            render={({ field: { onChange, value, ref } }) => {
              const languages = (options?.data?.language ?? []).map(
                mapOptionToSelect,
              );
              const selectedValue =
                languages.find((language) => language.value === value) ?? null;

              return (
                <div
                  ref={ref}
                  tabIndex={0}
                  data-name="brand-hub_manage-brand-profile_language_select-wrapper"
                  data-cy="brand-hub_manage-brand-profile_language_select-wrapper"
                >
                  <Select
                    options={languages}
                    onChange={(option) => {
                      onChange(option?.value ?? null);
                    }}
                    value={selectedValue}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    styles={{
                      menuPortal: (styles) => ({
                        ...styles,
                        zIndex: 1010,
                      }),
                    }}
                    data-name="brand-hub_manage-brand-profile_language"
                    data-cy="brand-hub_manage-brand-profile_language"
                  />
                </div>
              );
            }}
          />
          <Field.Error>{form.formState.errors.language?.message}</Field.Error>
          <Field.Group>
            <Field.Label>{t('logo')}</Field.Label>
            <Controller
              name="logoSrc"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <SingleImageFilePicker
                  imagePreview={typeof value === 'string' ? value : undefined}
                  onChange={(logo) => onChange(logo)}
                />
              )}
            />
            {!!form.formState.errors.name?.message && (
              <Field.Error>{form.formState.errors.name?.message}</Field.Error>
            )}
          </Field.Group>
          <Field.Group>
            <Field.Label required>{t('brandInfo')}</Field.Label>
            <TextArea
              data-name="brand-hub_manage-brand-profile_story"
              placeholder={t('storyPlaceholder')}
              {...form.register('story')}
            />
            {!!form.formState.errors.story?.message && (
              <Field.Error>{form.formState.errors.story?.message}</Field.Error>
            )}
          </Field.Group>
          <Field.Group>
            <Field.Label required>{t('usp')}</Field.Label>
            <TextArea
              data-name="brand-hub_manage-brand-profile_usp"
              placeholder={t('uspPlaceholder')}
              {...form.register('usp')}
            />
            {!!form.formState.errors.usp?.message && (
              <Field.Error>{form.formState.errors.usp?.message}</Field.Error>
            )}
          </Field.Group>
          <Field.Group>
            <Field.Label required>{t('targetAudience')}</Field.Label>
            <TextArea
              data-name="brand-hub_manage-brand-profile_audience"
              placeholder={t('targetAudiencePlaceholder')}
              {...form.register('audience')}
            />
            {!!form.formState.errors.audience?.message && (
              <Field.Error>
                {form.formState.errors.audience?.message}
              </Field.Error>
            )}
          </Field.Group>
          <Field.Group>
            <Field.Label>{t('otherInfo')}</Field.Label>
            <TextArea
              data-name="brand-hub_manage-brand-profile_other-info"
              placeholder={t('otherInfoPlaceholder')}
              {...form.register('otherInfo')}
            />
            {!!form.formState.errors.otherInfo?.message && (
              <Field.Error>
                {form.formState.errors.otherInfo?.message}
              </Field.Error>
            )}
          </Field.Group>
        </Field.Group>
        {profileId && (
          <>
            <Divider />
            <Field.Group>
              <div>
                <Button
                  data-name="brand-hub_manage-brand-profile_delete"
                  variant="danger"
                  isLoading={deleteProfile.isLoading}
                  onClick={() => onDeleteProfile(profileId)}
                >
                  {t('deleteBrandProfile')}
                </Button>
              </div>
            </Field.Group>
          </>
        )}
      </Modal.Content>
      <Modal.Footer withDivider>
        <Button
          onClick={onClose}
          variant="secondary"
          data-name="brand-hub_manage-brand-profile_close"
        >
          {t('close')}
        </Button>
        <Tooltip content={t('pleaseFillRequiredFormFields')}>
          <div>
            <Button
              type="submit"
              data-name="brand-hub_manage-brand-profile_save"
              disabled={!form.formState.isValid}
              isLoading={saveProfile.isLoading}
            >
              {t('save')}
            </Button>
          </div>
        </Tooltip>
      </Modal.Footer>
    </form>
  );
};

export default BrandHubManageProfileModalForm;
