import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import i18n from 'i18n';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import style from './Form.module.scss';
import { HashtagPreset } from 'types/HashtagPresets';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { HashtagPresetsApi } from 'api/hashtagPresets';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faCircleQuestion';
import {
  Button,
  Field,
  Icon,
  TextArea,
  TextInput,
  Toggle,
  Tooltip,
} from '@kontentino/ui';
import Modal from 'components/shared/Modal';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import ProfilesSelector from 'app/components/ProfilesSelector';

type Props = {
  onClose(): void;
  onSubmit(values: FormState): void;
  defaultValues?: Partial<
    Pick<HashtagPreset, 'id' | 'name' | 'hashtags' | 'pages' | 'isGlobal'>
  > | null;
  isLoading: boolean;
};

type FormState = z.infer<typeof schema>;

const schema = z.object({
  id: z.number().optional().or(z.string()),
  name: z.string(),
  hashtags: z.string(),
  pages: z.array(z.number()),
  isGlobal: z.boolean(),
});

const Form: FC<Props> = (props) => {
  const { register, control, handleSubmit, formState, watch } =
    useForm<FormState>({
      resolver: zodResolver(schema),
      defaultValues: props.defaultValues
        ? {
            hashtags: props.defaultValues.hashtags,
            name: props.defaultValues.name,
            pages: props.defaultValues.pages,
            id: props.defaultValues.id,
            isGlobal: props.defaultValues.isGlobal,
          }
        : undefined,
    });
  const isGlobal = watch('isGlobal');
  const { data } = useQuery(
    [queryKey.hashtagPresetsAssignablePages],
    HashtagPresetsApi.getAssignablePages,
  );

  const pages = data ?? [];

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <Modal.Content className="tw-flex tw-flex-col tw-gap-4">
        <input type="hidden" {...register('id')} />
        <Field.Group>
          <Field.Label required>Preset name</Field.Label>
          <TextInput {...register('name')} />
          <Field.Error>{formState.errors.name?.message}</Field.Error>
        </Field.Group>
        <Field.Group>
          <Field.Label required>Hashtags</Field.Label>
          <TextArea {...register('hashtags')} className={style.textarea} />
          <Field.Error>{formState.errors.hashtags?.message}</Field.Error>
        </Field.Group>
        <Controller
          control={control}
          name="isGlobal"
          defaultValue={false}
          render={({ field: { value, onChange } }) => (
            <Field.Group>
              <div
                data-name="hashtag-presets_edit-modal_global-toggle"
                className="tw-flex tw-items-center"
              >
                <Field.Label
                  required
                  className="tw-flex tw-items-center tw-gap-2"
                >
                  Global preset{' '}
                  <Toggle checked={value} onChange={() => onChange(!value)} />
                  <Tooltip content="Global hashtag presets will be visible in all the social media profiles in your account.">
                    <Icon icon={faCircleQuestion} />
                  </Tooltip>
                </Field.Label>
              </div>
            </Field.Group>
          )}
        />
        <Controller
          control={control}
          name="pages"
          defaultValue={[]}
          render={({ field: { value, onChange } }) => {
            const allPageIds = pages.map((page) => page.id);

            return (
              <Field.Group>
                <Field.Label required={!isGlobal}>Profiles</Field.Label>
                <ProfilesSelector
                  disabledIds={isGlobal ? allPageIds : []}
                  profiles={pages.map((page) => ({
                    ...page,
                    logo: page.logoSrc ? { src: page.logoSrc } : undefined,
                  }))}
                  selectedIds={value}
                  onSelect={(id) =>
                    onChange(togglePrimitiveItemInArray(id, value))
                  }
                  onToggleAllSelection={(selection) =>
                    selection === 'all' ? onChange(allPageIds) : onChange([])
                  }
                />
              </Field.Group>
            );
          }}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={props.onClose}
          data-name="hashtag-presets_edit-modal_cancel"
        >
          {i18n.close}
        </Button>
        <Button
          variant="primary"
          type="submit"
          isLoading={props.isLoading}
          data-name="hashtag-presets_edit-modal_save"
        >
          {i18n.confirm}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Form;
