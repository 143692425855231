import { FC } from 'react';
import Modal from 'components/shared/Modal';
import { Button, Field, Icon } from '@kontentino/ui';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { BrandHubApi } from 'app/modules/brandHub/api/brandHub';
import { ApiClientError } from 'api/client';
import { useTranslation } from 'react-i18next';
import { useToast } from 'app/hooks/useToast';
import AIGenerateButton from 'app/modules/aiContent/components/AIGenerateButton';
import { queryKey } from 'constants/queryKey';
import { BrandProfile, WebCrawlResultSchema } from 'app/modules/brandHub/types';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import WebCrawlLinkField from 'app/modules/brandHub/components/WebCrawlLinkField';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBrandProfileSuggested: (brandProfile: BrandProfile) => void;
};

const schema = z.object({
  webCrawl: WebCrawlResultSchema,
});

type FormValues = z.infer<typeof schema>;

const BrandHubSuggestProfileModal: FC<Props> = ({
  isOpen,
  onClose,
  onBrandProfileSuggested,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { accountPlanType } = useSubscriptionInfo();
  const queryClient = useQueryClient();
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      webCrawl: undefined,
    },
  });
  const mutation = useMutation(BrandHubApi.suggestBrandProfile, {
    onSuccess: (brandProfile) => {
      onClose();
      onBrandProfileSuggested(brandProfile);
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey.aiContentOptions());
    },
  });

  function onSubmit({ webCrawl }: FormValues) {
    mutation.mutate({
      bodyText: webCrawl?.bodyText,
      planType: accountPlanType,
      logo: webCrawl?.logo,
    });
  }

  return (
    <Modal
      dataName={{
        closeButton: 'brand-hub_suggest-profile_close',
        wrapper: 'brand-hub_suggest-profile_modal',
      }}
      dataCy={{
        closeButton: 'brand-hub_suggest-profile_close',
        wrapper: 'brand-hub_suggest-profile_modal',
      }}
      classNames={{
        root: '!tw-z-[1002]',
      }}
      open={isOpen}
      onClose={onClose}
      isLoading={mutation.isLoading}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Modal.Header
          title={t('generateBrandProfileTitle')}
          subtitle={t('generateBrandProfileFromWebsiteDescription')}
        />

        <Modal.Content className="tw-space-y-4 tw-pb-4">
          <Field.Group>
            <Field.Label required>
              <Icon icon={faGlobe} className="tw-mr-1" />
              {t('webLink')}
            </Field.Label>
            <Controller
              control={form.control}
              name="webCrawl"
              render={({ field }) => (
                <WebCrawlLinkField
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={t('brandProfileWebsiteLinkPlaceholder')}
                  dataNamePrefix="brand-hub_suggest-profile_web-crawl-link"
                />
              )}
            />
            {!!form.formState.errors.webCrawl?.message && (
              <Field.Error>
                <>{form.formState.errors.webCrawl?.message}</>
              </Field.Error>
            )}
          </Field.Group>
        </Modal.Content>
        <Modal.Footer withDivider>
          <Button
            data-name="brand-hub_suggest-profile_close"
            onClick={onClose}
            variant="secondary"
          >
            {t('fillManually')}
          </Button>
          <AIGenerateButton
            type="button"
            data-name="brand-hub_suggest-profile_generate"
            onClick={form.handleSubmit(onSubmit)}
            isLoading={mutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default BrandHubSuggestProfileModal;
