import { ProfileSelectorFilterOptionKey } from 'app/components/profileSelector/types/profileSelectorFilter';
import { PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES } from 'app/components/profileSelector/constants';

export const SIDEBAR_DEFAULT_OPTIONS = [
  {
    key: ProfileSelectorFilterOptionKey.All,
    ...PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES[
      ProfileSelectorFilterOptionKey.All
    ],
  },
  {
    key: ProfileSelectorFilterOptionKey.Selected,
    ...PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES[
      ProfileSelectorFilterOptionKey.Selected
    ],
  },
  {
    key: ProfileSelectorFilterOptionKey.Profiles,
    ...PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES[
      ProfileSelectorFilterOptionKey.Profiles
    ],
  },
  {
    key: ProfileSelectorFilterOptionKey.GlobalProfiles,
    ...PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES[
      ProfileSelectorFilterOptionKey.GlobalProfiles
    ],
  },
  {
    key: ProfileSelectorFilterOptionKey.ProfileGroups,
    ...PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES[
      ProfileSelectorFilterOptionKey.ProfileGroups
    ],
  },
];
