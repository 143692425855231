import { FC } from 'react';
import ProfileSelectorOption from 'app/components/profileSelector/components/ProfileSelectorOption';
import AllSelectionToggler from 'app/components/AllSelectionToggler';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { mergeTailwindClasses } from '@kontentino/ui';

type Props = {
  profiles: {
    id: any;
    name: string;
    logo?: {
      src: string;
    } | null;
    type: number;
    isGlobal?: boolean;
  }[];
  onSelect: (id: any) => void;
  selectedIds: any[];
  disabledIds?: any[];
  onToggleAllSelection?: (value: 'all' | 'none') => void;
  className?: {
    wrapper?: string;
    list?: string;
  };
  isSingleSelect?: boolean;
};

const ProfilesSelector: FC<Props> = ({
  profiles,
  onSelect,
  selectedIds,
  disabledIds,
  onToggleAllSelection,
  className,
  isSingleSelect,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={mergeTailwindClasses(
        clsx('tw-flex  tw-flex-col', className?.wrapper),
      )}
      data-cy="profile-select-list"
      data-name="profile-select-list"
    >
      <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between tw-bg-grayscale-10 tw-px-3 tw-text-md">
        <span className="tw-text-md tw-font-bold">
          {t('selected')}: {selectedIds.length}
        </span>
        {onToggleAllSelection && (
          <AllSelectionToggler
            dataCy={{
              allButton: 'profile-selector-all-button',
              noneButton: 'profile-selector-none-button',
            }}
            selected={
              selectedIds.length === profiles.length ? 'all' : undefined
            }
            onSelect={onToggleAllSelection}
          />
        )}
      </div>
      <div
        className={mergeTailwindClasses(
          clsx(
            'tw-flex tw-max-h-[400px] tw-flex-col tw-overflow-auto',
            className?.list,
          ),
        )}
      >
        {profiles.map((profile) => {
          return (
            <ProfileSelectorOption
              isSingleSelect={isSingleSelect}
              dataCy="profile-select-option"
              dataName="profile-select-option"
              key={profile.id}
              option={{
                label: profile.name,
                logoSrc: profile.logo?.src,
                socialMediaType: profile.type,
              }}
              eventHandlers={{
                onClick: () => onSelect(profile.id),
              }}
              isChecked={selectedIds.includes(profile.id)}
              isDisabled={disabledIds?.includes(profile.id)}
              isGlobal={profile.isGlobal}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProfilesSelector;
